<template>
  <div>
    <a-row :gutter="16">
      <a-col :span="24">
        <a-form-item label="NIK">
          <a-input
            size="large"
            class="w-100"
            :value="dataTeacher.nik"
            @change="(e) => handleChange(e.target.value, 'nik')"
          />
        </a-form-item>
      </a-col>
      <a-col :span="24">
        <a-form-item label="Full Name">
          <a-input
            size="large"
            class="w-100"
            :value="dataTeacher.nama"
            @change="(e) => handleChange(e.target.value, 'nama')"
          />
        </a-form-item>
      </a-col>
      <a-col :span="24">
        <a-form-item label="E-mail">
          <a-input
            size="large"
            class="w-100"
            :value="dataTeacher.email"
            @change="(e) => handleChange(e.target.value, 'email')"
          />
        </a-form-item>
      </a-col>
      <a-col :span="24">
        <a-form-item label="Gender">
          <a-radio-group
            class="w-100"
            size="large"
            default-value="Laki-Laki"
            button-style="solid"
            :value="dataTeacher.jenis_kelamin"
            @change="(e) => handleChange(e.target.value, 'jenis_kelamin')"
          >
            <a-radio-button class="w-50 text-center" value="Laki-Laki">
              Laki-Laki
            </a-radio-button>
            <a-radio-button class="w-50 text-center" value="Perempuan">
              Perempuan
            </a-radio-button>
          </a-radio-group>
        </a-form-item>
      </a-col>
      <a-col :span="24">
        <a-form-item label="Subject">
            <a-select mode="multiple" show-search @change="(e) => handleChange(e, 'id_mata_pelajaran')" class="w-100" placeholder="Select Subject" size="large" :value="dataTeacher.id_mata_pelajaran">
              <a-select-option v-for="mapel in listSubjects" :key="mapel.id" :value="mapel.id">{{mapel.nama}}</a-select-option>
            </a-select>
          </a-form-item>
      </a-col>
      <a-col :span="24">
        <a-form-item label="Address">
          <a-textarea
            class="w-100"
            :value="dataTeacher.alamat"
            @change="(e) => handleChange(e.target.value, 'alamat')"
            :rows="4"
          />
        </a-form-item>
      </a-col>
    </a-row>
  </div>
</template>

<script>
export default {
  props: {
    dataTeacher: {
      type: Object,
      required: true,
    },
    listSubjects: {
      type: Array,
    },
  },
  methods: {
    handleChange(value, column) {
      const payload = { value, column }
      this.$emit('handle-change', payload)
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
  },
}
</script>

<style>
</style>
